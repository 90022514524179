function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function changeStyleStart(newCss) {
    var styles = document.createElement('link');

    styles.rel = "stylesheet";
    styles.setAttribute("data-link", "cssAdd");
    styles.href = "/szablony/portal/css/" + newCss + ".min.css";
    document.head.prepend(styles);
}


if (getCookie("contrast") != "") {
    let css = getCookie("contrast");
    changeStyleStart(css);
        
}